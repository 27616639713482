import { GridRow, RowHeight } from '@oresundsbron/api';
import { Box, PageWrapper, Grid } from '@oresundsbron/bridge-ui';
import { cva, cx } from 'class-variance-authority';
import { pipe } from 'fp-ts/lib/function';
import { chain, fromNullable, getOrElse, getOrElseW } from 'fp-ts/lib/Option';
import { lookup } from 'fp-ts/lib/Record';
import { FC, useMemo } from 'react';
import { Column } from './Column';

const cols = {
  1: 'md:grid-cols-1',
  2: 'md:grid-cols-2',
  3: 'md:grid-cols-3',
  4: 'md:grid-cols-4',
};

const getGridCols = (l: number) =>
  pipe(
    cols,
    lookup(`${l}`),
    getOrElse(() => 'md:grid-cols-1')
  );

const backgrounds = {
  'inform.100': 'bg-blue-100',
  'inform.200': 'bg-blue-200',
  'inform.300': 'bg-blue-600',
  'inform.500': 'bg-blue-800',
  'negative.100': 'bg-red-100',
  'negative.200': 'bg-red-300',
  'negative.300': 'bg-red-400',
  'negative.500': 'bg-red-200',
  'negative.700': 'bg-red-700',
  'neutral.50': 'bg-neutral-50',
  'neutral.100': 'bg-neutral-100',
  'neutral.200': 'bg-neutral-200',
  'neutral.400': 'bg-neutral-400',
  'neutral.500': 'bg-neutral-500',
  'neutral.700': 'bg-neutral-700',
  'neutral.900': 'bg-neutral-900',
  'notice.100': 'bg-yellow-100',
  'notice.200': 'bg-yellow-200',
  'notice.300': 'bg-yellow-300',
  'positive.100': 'bg-green-100',
  'positive.200': 'bg-green-200',
  'positive.300': 'bg-neutral-400',
  'positive.600': 'bg-green-800',
  'primary.50': 'bg-primary-50',
  'primary.100': 'bg-primary-100',
  'primary.200': 'bg-primary-200',
  'primary.300': 'bg-primary-300',
  'primary.400': 'bg-primary-400',
  'primary.600': 'bg-primary-600',
  'primary.800': 'bg-primary-800',
  'primary.900': 'bg-primary-900',
  'primary.950': 'bg-primary-950',
};

const heights: Record<RowHeight, string> = {
  Auto: 'min-h-auto',
  Medium: 'min-h-[20rem] md:min-h-[24rem]',
  Large: 'min-h-[21rem] md:min-h-[34rem]',
};

const getBackground = (str: string) =>
  pipe(
    backgrounds,
    lookup(str),
    getOrElseW(() => undefined)
  );

const getHeight = (str?: RowHeight) =>
  pipe(
    str,
    fromNullable,
    chain((s) => lookup(s)(heights)),
    getOrElseW(() => undefined)
  );

const Width = cva(['col-span-full'], {
  variants: {
    size: {
      Full: [],
      '2/3': [
        'sm:col-span-6',
        'md:col-span-8',
        'sm:col-start-2',
        'md:col-start-3',
      ],
    },
  },
  defaultVariants: {
    size: 'Full',
  },
});

export const Row: FC<GridRow & { pageWrapper?: boolean }> = ({
  columns,
  background,
  bleed,
  height,
  width,
  pageWrapper = true,
}) => {
  const cols = useMemo(
    () =>
      columns.map((col, i) => (
        <Column
          bleed={bleed}
          key={col.sys.id}
          {...col}
          pos={i}
          total={columns.length}
          height={getHeight(height)}
        />
      )),
    [columns, bleed, height]
  );

  if (bleed) {
    return (
      <Box
        className={cx(
          'col-span-3',
          'grid',
          'grid-cols-1',
          getGridCols(columns.length),
          'md:auto-rows-fr',
          background ? getBackground(background.value) : undefined
        )}
      >
        {cols}
      </Box>
    );
  }

  if (!pageWrapper) {
    return (
      <Grid>
        <Box
          className={cx(
            Width({ size: width }),
            'grid',
            'grid-cols-1',
            getGridCols(columns.length),
            'md:auto-rows-fr',
            background ? getBackground(background.value) : undefined,
            getHeight(height)
          )}
        >
          {cols}
        </Box>
      </Grid>
    );
  }

  return (
    <PageWrapper as="div">
      <Grid className="col-start-2">
        <Box
          className={cx(
            Width({ size: width }),
            'grid',
            'grid-cols-1',
            getGridCols(columns.length),
            'md:auto-rows-fr',
            background ? getBackground(background.value) : undefined,
            getHeight(height)
          )}
        >
          {cols}
        </Box>
      </Grid>
    </PageWrapper>
  );
};
